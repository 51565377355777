.about-sec {
    text-align: center;
    padding: 80px 0;
}
.about-sec h2 {
    font-size: 54px;
    line-height: 55px;
    color: #000;
    text-transform: uppercase;
}
.about-sec h2 span {
    -webkit-text-stroke: 2px #000;
    color: transparent;
}
.about-sec p.sub-pera {
    font-size: 14px;
    background: linear-gradient(to right, #6dab3c 40%, #0f3fd8 100%);
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent;
}
.about-sec p {
    font-size: 15px;
    margin-bottom: 20px;
    color: #777C84;
    padding: 0 60px;
}
.about-sec .btn-area {
    margin-top: 50px;
}
.about-sec .btn {
    font-size: 16px;
    padding: 12px 40px;
    background: linear-gradient(to bottom right, #3b72ef 40%, #39d5aa 100%);
    border: 2px solid transparent;
    border-radius: 35px;
    margin-right: 15px;
}
.about-sec .btn.danger {
    background: linear-gradient(to bottom right, #6dab3c 40%, #0f3fd8 100%);
    border: 2px solid transparent;
}


@media (max-width: 767px) {
    .about-sec {
        padding: 60px 0;
    }
    .about-sec h2 {
        font-size: 40px;
        line-height: 40px;
        margin-bottom: 20px;
    }
    .about-sec p.sub-pera {
        font-size: 15px;
        padding: 0;
    }
    .about-sec p {
        font-size: 15px;
        padding: 0;
    }
    .about-sec .btn {
        margin: 0 0 15px;
    }
}