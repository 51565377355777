/* banner */
.banner {
    background: url('../../assets/images/home/banner-bg.png') no-repeat center center;
    background-size: cover;
    padding: 140px 0 80px;
    overflow: hidden;
    position: relative;
    color: #fff;
    display: flex;
    align-items: center;
    min-height: 85vh;
}
.banner .text-area h1 {
    font-size: 48px;
    line-height: 52px;
    text-transform: uppercase;
}
.banner .text-area h1 span {
    -webkit-text-stroke: 2px #fff;
    color: transparent;
}
.banner .text-area p {
    font-size: 16px;
    color: #B3B6B9;
}
.banner .text-area p span {
    color: #0EE791;
}
.banner .text-area .btn {
    font-size: 14px;
    padding: 12px 50px;
    background: linear-gradient(to bottom right, #3b72ef 40%, #39d5aa 100%);
    border: 2px solid transparent;
    border-radius: 35px;
}
.banner .form-area .box {
    background: #fff;
    padding: 10px 10px;
    border-radius: 35px;
}
.banner .form-area .box .offer {
    background: linear-gradient(to bottom right, #6dab3c 40%, #0f3fd8 100%);;
    text-align: center;
    border-radius: 35px;
    padding: 35px 20px;
    margin-bottom: 15px;
}
.banner .form-area .box .offer p {
    font-size: 20px;
    text-transform: capitalize;
    line-height: 25px;
    margin: 0;
}
.banner .form-area .box .offer p span {
    font-size: 24px;
}
.banner .form-area .box form {
    padding: 0 20px;
}
.banner .form-area .box form .form-group {
    border: 1px solid #ddd;
    margin-top: 12px;
    margin-bottom: 3px;
    padding: 20px 20px 5px;
    border-radius: 20px;
    position: relative;
}
.banner .form-area .box form .form-group label {
    font-size: 11px;
    color: #A9AEB1;
    position: absolute;
    top: 10px;
}
.banner .form-area .box form .form-group .form-control {
    border: 0;
    height: 30px;
    padding: 2px 0;
}
.banner .form-area .box form .form-group textarea.form-control {
    height: 50px;
    resize: none;
}
.banner .form-area .box form .submit-btn {
    width: 100%;
    font-size: 14px;
    color: #fff;
    padding: 18px 20px;
    background: linear-gradient(to bottom right, #3b72ef 40%, #39d5aa 100%);;
    border: 1px solid #00B67A;
    border-radius: 20px;
    margin-bottom: 12px;
}
.banner .form-area .box form p.success-msg {
    color: #000;
    text-align: center;
    font-size: 13px;
    display: none;
}


.achievements {
    background: #E3E3E3;
    padding: 20px 0;
}
.achievements img {
    height: 100px;
    object-fit: contain;
    margin: 0 20px;
}

.services-sec {
    padding: 60px 0;
    min-height: 100vh;
}
.services-sec .head-content {
    margin-bottom: 50px;
}
.services-sec .head-content .divider {
    display: block;
    width: 15px;
    height: 2px;
    background: linear-gradient(to bottom right, #3b72ef 40%, #39d5aa 100%);
    margin-bottom: 25px;
}
.services-sec .head-content p {
    font-size: 13px;
}
.services-sec .head-content .btn {
    font-size: 14px;
    padding: 12px 50px;
    background: linear-gradient(to bottom right, #3b72ef 40%, #39d5aa 100%);
    border: 2px solid transparent;
    border-radius: 35px;
}
.services-sec .head-content h2 {
    font-size: 46px;
    line-height: 46px;
    color: #000;
    text-transform: uppercase;
}
.services-sec .head-content h2 span {
    -webkit-text-stroke: 2px #000;
    color: transparent;
}
/* .services-sec .slider {
    display: flex;
    justify-content: space-between;
    gap: 10px;
} */
.services-sec .slider .box {
    background: url('../../assets/images/home/services/slide-bg.png') no-repeat center center;
    background-size: cover;
    width: 100%;
    padding: 50px 25px 30px;
    border-radius: 30px;
    color: #fff;
    position: relative;
    min-height: 450px;
    transition: .3s;
}
.services-sec .slider .box:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    width: 100%;
    height: 100%;
    background: linear-gradient(to bottom right, #3b72ef 40%, #39d5aa 100%);
    border-radius: 30px;
}
.services-sec .slider .slick-track > div:nth-child(2) .box:before,
.services-sec .slider .slick-track > div:nth-child(7) .box:before {
    background: linear-gradient(to bottom right, #e67c25 0%, #260029 100%)
}
.services-sec .slider .slick-track > div:nth-child(3) .box:before,
.services-sec .slider .slick-track > div:nth-child(8) .box:before {
    background: linear-gradient(to bottom right, #5c6e97 0%, #011c41 100%);
}
.services-sec .slider .slick-track > div:nth-child(4) .box:before,
.services-sec .slider .slick-track > div:nth-child(9) .box:before {
    background: linear-gradient(to bottom right, #ff2ad0 0%, #010920 100%);

}
.services-sec .slider .slick-track > div:nth-child(5) .box:before,
.services-sec .slider .slick-track > div:nth-child(10) .box:before {
    background: linear-gradient(to bottom right, #f7be68 0%, #f24a4a 100%);
}
.services-sec .slider .slick-slide {
    transition: .3s;
}



.services-sec .slider .slick-list {
    overflow: hidden;
}
.services-sec .slider .slick-track {
    display: flex;
    gap: 10px;
}
.services-sec .slider .box .text-area {
    position: relative;
    z-index: 1;
}
.services-sec .slider .box .number {
    font-size: 30px;
    font-family: CD-SemiBold;
}
.services-sec .slider .box h3 {
    font-size: 15px;
    line-height: 22px;
    font-family: CD-Medium;
    text-transform: uppercase;
    margin-bottom: 20px;
    min-height: 44px;
}
.services-sec .slider .box p {
    font-size: 14px;
    margin: 0;
}

.services-sec .slider .slick-slide .box img {
    display: none;
    transition: .3s;
    margin-bottom: 20px;
}


.feature-sec {
    background: #161616;
    padding: 80px 0;
    color: #fff;
    text-align: center;
}
.feature-sec h2 {
    font-size: 60px;
    line-height: 55px;
    color: #fff;
    text-transform: uppercase;
}
.feature-sec h2 span {
    -webkit-text-stroke: 1px #fff;
    color: transparent;
}
.feature-sec p {
    font-size: 14px;
    width: 550px;
    margin: 0 auto 30px;
}
.feature-sec ul {
    list-style: none;
    padding: 0;
    margin: 0;
    margin-bottom: 60px;
    display: flex;
    justify-content: space-around;
    border-bottom: 1px solid #ddd;
}
.feature-sec ul li {
    position: relative;
}
.feature-sec ul li.active:after {
    content: '';
    position: absolute;
    bottom: -2px;
    left: 10px;
    height: 4px;
    width: 20px;
    background: linear-gradient(to bottom right, #3b72ef 40%, #39d5aa 100%);
}
.feature-sec ul li button {
    font-size: 12px;
    color: #fff;
    background: transparent;
    border: 0;
    padding: 15px 10px;
}
.feature-sec .img-area {
    display: flex;
    flex-wrap: wrap;
    padding: 0 10px;
}
.feature-sec .img-area img {
    flex-grow: 1;
    width: 33%;
    padding: 10px 10px;
}

.stats {
    text-align: center;
}
.stats .box {
    background: linear-gradient(to bottom right, #3b72ef 40%, #39d5aa 100%);;
    color: #fff;
    padding: 50px 15px;
}
.stats .row > div:nth-child(even) .box {
    background: linear-gradient(to bottom right, #6dab3c 40%, #0f3fd8 100%);
}
.stats .box h3 {
    font-size: 30px;
    font-family:CD-Medium;
}
.stats .box p {
    font-size: 15px;
    margin: 0;
}


.process {
    padding: 80px 0;
    overflow: hidden;
}
.process h2 {
    font-size: 58px;
    line-height: 55px;
    color: #000;
    text-transform: uppercase;
    margin-bottom: 30px;
}
.process h2 span {
    -webkit-text-stroke: 2px #000;
    color: transparent;
}
.process h3 {
    font-size: 18px;
    font-family: CD-Regular;
    text-transform: uppercase;
}
.process p {
    font-size: 13px;
    color: #69717A;
}
.process .side-img {
    transform: scale(1.2);
    position: relative;
    top: 70px;
    left: 65px;
}


.technologies-sec {
    background: #fff;
    padding: 60px 0;
    text-align: center;
}
.technologies-sec h2 {
    font-size: 32px;
    color: #000;
    font-weight: 700;
    margin: 0 0 15px;
    text-transform: uppercase;
    line-height: 35px;
    font-family:CD-Medium;
}
.technologies-sec p {
    font-size: 14px;
    padding: 0 10px;
}
.technologies-sec ul {
    padding: 0;
    margin: 0;
    list-style: none;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    margin-top: 30px;
    position: relative;
}
.technologies-sec ul:before {
    content: '';
    background: url('../../assets/images/home/tech/good_luck.png') no-repeat center center;
    background-size: contain;
    position: absolute;
    top: -180px;
    left: -80px;
    width: 150px;
    height: 155px;
}
.technologies-sec ul li {
    width: calc(20%);
    padding: 10px 20px;
}
.technologies-sec ul li img {
    filter: grayscale(100%);
    transition: all .5s ease-in-out;
}

.technologies-sec ul li:hover img {
    filter: grayscale(0);
    transform: rotateY(360deg);
}


.testimonials {
    background: url('../../assets/images/home/testimonials/bg.png') no-repeat center center;
    background-size: cover;
    padding: 30px 0;
    overflow: hidden;
}
.testimonials .box {
    padding: 30px 30px;
    background: #fff;
    display: flex;
    align-items: flex-start;
    border-radius: 40px;
    width: calc(100% - 50px);
    user-select: none;
    transition: .5s;
    margin-bottom: 20px;
    box-shadow: 0 0 20px #e9e9e9;
}
.testimonials .slick-active .box {
    margin-left: 80px;
    transition: .5s;
}
.testimonials .box img {
    width: 70px;
    margin-right: 20px;
}
.testimonials .box h3 {
    font-size: 16px;
    text-transform: uppercase;
    color: #151515;
    font-family:CD-Medium;
    margin: 0;
}
.testimonials .box h4 {
    font-size: 12px;
    color: #9AA0A3;
    margin-bottom: 15px;
}
.testimonials .box p {
    font-size: 12px;
    color: #69717A;
    margin-bottom: 0;
}
.testimonials .slick-slider {
    height: 595px;
    padding-top: 120px;
}
.testimonials .right-side {
    padding-left: 30px;
}
.testimonials .right-side h2 {
    font-size: 48px;
    line-height: 47px;
    color: #000;
    text-transform: uppercase;
    margin-bottom: 30px;
}
.testimonials .right-side h2 span {
    -webkit-text-stroke: 2px #000;
    color: transparent;
}
.testimonials .right-side p {
    font-size: 15px;
    color: #69717A;
    padding-right: 60px;
}


.contact-sec {
    padding: 100px 0 80px;
}
.contact-sec .left-side h2 {
    font-size: 30px;
    color: #000;
    line-height: 32px;
    text-transform: uppercase;
    margin-bottom: 15px;
    font-family:CD-Medium;
}
.contact-sec .left-side h2 span {
    -webkit-text-stroke: .3px #000;
    color: transparent;
}
.contact-sec .left-side p {
    font-size: 15px;
    width: 75%;
    color: #69717A;
}
.contact-sec .left-side .btns-area {
    margin-top: 30px;
}
.contact-sec .left-side .btn {
    font-size: 14px;
    padding: 12px 30px;
    background: linear-gradient(to bottom right, #3b72ef 40%, #39d5aa 100%);
    border: 2px solid transparent;
    border-radius: 35px;
    margin-right: 15px;
}
.contact-sec .left-side .btn.danger {
    background: linear-gradient(to bottom right, #6dab3c 40%, #0f3fd8 100%);
    border: 2px solid transparent;
}
.contact-sec form label {
    font-size: 12px;
    color: #848E96;
    margin-left: 15px;
    margin-top: 10px;
}
.contact-sec form .form-control {
    height: 50px;
    font-size: 15px;
    border-radius: 15px;
    margin-bottom: 4px;
    padding: 5px 25px;
}
.contact-sec form textarea.form-control {
    height: 130px;
    resize: none;
}
.contact-sec .submit-btn {
    font-size: 14px;
    padding: 12px 50px;
    color: #fff;
    background: linear-gradient(to bottom right, #3b72ef 40%, #39d5aa 100%);
    border: 2px solid transparent;
    border-radius: 35px;
    margin-right: 15px;
    margin-top: 30px;
}


.footer-links {
    padding: 80px 0;
    background: #000000;
    color: #fff;
}
.footer-links h3 {
    font-size: 14px;
    font-family: CD-Regular;
    position: relative;
    padding-bottom: 8px;
    margin-bottom: 50px;
}
.footer-links h3:before {
    content: '';
    position: absolute;
    left: 0;
    bottom: 0;
    display: block;
    width: 60px;
    height: 2px;
    background: #fff;
}

.footer-links h3:after {
    content: '';
    position: absolute;
    left: 0;
    bottom: 0;
    display: block;
    width: 120%;
    height: 1px;
    background: #707070;
}
.footer-links .row > div:last-child h3:after {
    width: 100%;
}
.footer-links h2 {
    font-size: 20px;
    font-family: CD-Regular;
    text-transform: uppercase;
}
.footer-links p {
    font-size: 15px;
    color: #ACB0B5;
}
.footer-links p.extra-p {
    padding-right: 100px;
}
.footer-links .phone {
    display: inline-flex;
    align-items: flex-start;
    margin-bottom: 10px;
}
.footer-links .phone i {
    margin-right: 10px;
    font-size: 18px;
    color: #00B67A;
    margin-top: 3px;
}
.footer-links .phone span {
    font-size: 15px;
    color: #ACB0B5;
    transition: all .2s ease-in-out;
}
.footer-links .phone:hover span {
    color: #fff;
}
.footer-links .payment-img {
    margin-top: 10px;
}
.footer-links ul {
    list-style: none;
    margin: 0;
    padding: 0;
}
.footer-links ul li a {
    font-size: 14px;
    padding-right: 100px;
    color: #ACB0B5;
}
.footer-links ul li a:hover {
    color: #fff;
}
.footer-links h4 {
    font-size: 14px;
    font-family: CD-Regular;
    position: relative;
    padding-bottom: 8px;
    margin-bottom: 5px;
    letter-spacing: .5px;
}
.footer-links form .form-group {
    display: flex;
    align-items: center;
    position: relative;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.footer-links form .form-group .form-control {
    height: 50px;
    font-size: 13px;
    padding: 8px 65px 8px 20px;
    border-radius: 50px;
}
.footer-links form .form-group .submit-btn {
    position: absolute;
    right: 4px;
    padding: 13px 20px;
    background: linear-gradient(to bottom right, #6dab3c 40%, #0f3fd8 100%);
    border: 0;
    color: #fff;
    font-size: 12px;
    border-radius: 30px;
}
.footer-links .social h4 {
    margin-top: 25px;
    padding-bottom: 0;
    margin-bottom: 10px;
    text-transform: uppercase;
}
.footer-links .social ul {
    display: flex;
    align-items: center;
    justify-content: flex-start;
}
.footer-links .social ul li a {
    padding: 0;
    margin-right: 20px;
}
.footer-links .social ul li a img {
    width: 25px;
}

.copyright {
    padding: 30px 0;
    text-align: center;
    color: #fff;
    background: linear-gradient(to bottom right, #6dab3c 40%, #0f3fd8 100%);
}
.copyright p {
    margin: 0;
}



@media (min-width: 992px) {
    .services-sec .slider .slick-slide:hover {
        width: 320px !important;
    }
    .services-sec .slider .slick-slide:hover .box{
        display: flex;
        align-items: center;
    }
    .services-sec .slider .slick-slide:hover .box,
    .services-sec .slider .slick-slide:hover .box:before {
        background: #fff !important;
        color: #000;
        padding: 20px 20px;
        border: 1px solid #f3f3f3;
    }
    .services-sec .slider .slick-slide:hover .box img {
        display: block;
    }
    .services-sec .slider .slick-slide:hover .box p {
        display: none;
    }
    .services-sec .slider .slick-slide:hover .box .number {
        position: absolute;
        right: 0;
        color: #DF2A3B;
    }
    .services-sec .slider .slick-slide:hover .box h3 {
        width: calc(100% - 50px);
    }
}



@media (max-width: 1024px) {
    .banner .text-area h1 {
        font-size: 72px;
        line-height: 70px;
    }
    .achievements img {
        height: 80px;
    }
    .services-sec .head-content h2 {
        font-size: 50px;
        line-height: 45px;
    }
    .services-sec .slider .box h3 {
        font-size: 15px;
        line-height: 20px;
    }
    .feature-sec h2 {
        font-size: 50px;
        line-height: 50px;
    }
    .stats .box h3 {
        font-size: 26px;
    }
    .stats .box p {
        font-size: 13px;
    }
    .services-sec .slider .box {
        min-height: 510px;
    }
    .process h2 {
        font-size: 48px;
        line-height: 45px;
        margin-bottom: 20px;
    }
    .process h3 {
        font-size: 15px;
    }
    .technologies-sec h2 {
        font-size: 26px;
    }
    .technologies-sec p {
        padding: 0 30px;
    }
    .testimonials {
        padding: 30px 0 70px;
    }
    .testimonials .slick-slider {
        padding-top: 210px;
    }
    .testimonials .right-side h2 {
        font-size: 42px;
        line-height: 40px;
        margin-bottom: 25px;
    }
    .testimonials .right-side p {
        padding-right: 60px;
    }
    .contact-sec .left-side h2 {
        font-size: 26px;
        line-height: 30px;
        margin-bottom: 10px;
    }
    .footer-links h2 {
        font-size: 16px;
    }
    .footer-links p.extra-p {
        padding-right: 50px;
    }
}


@media (max-width: 991px) {
    .banner .text-area h1 {
        font-size: 52px;
        line-height: 55px;
    }
    .banner .form-area .box .offer {
        padding: 25px 20px;
    }
    .banner .form-area .box .offer p {
        font-size: 16px;
        line-height: 24px;
    }
    .achievements img {
        height: 65px;
    }
    .services-sec .slider .box {
        min-height: auto;
    }
    .services-sec .head-content h2 {
        font-size: 36px;
        line-height: 38px;
    }
    .feature-sec h2 {
        font-size: 42px;
        line-height: 42px;
    }
    .stats .box h3 {
        font-size: 20px;
    }
    .stats .box p {
        font-size: 11px;
    }
    .process h2 {
        font-size: 40px;
        line-height: 40px;
    }
    .technologies-sec h2 {
        font-size: 22px;
        line-height: 26px;
    }
    .technologies-sec p {
        padding: 0;
    }
    .testimonials .box {
        padding: 50px 20px;
    }
    .testimonials .box img {
        width: 60px;
        margin-right: 15px;
    }
    .testimonials .right-side h2 {
        font-size: 30px;
        line-height: 30px;
    }
    .testimonials .right-side p {
        padding-right: 15px;
    }
    .contact-sec .left-side h2 {
        font-size: 20px;
        line-height: 26px;
    }
    .contact-sec .left-side p {
        width: 70%;
    }
    .contact-sec form .form-control {
        height: 40px;
    }
    .footer-links {
        padding: 20px 0 50px;
    }
    .footer-links h3 {
       margin-top: 40px;
       margin-bottom: 20px;
    }
    
}



@media (max-width: 767px) {
    
    .banner {
        text-align: center;
    }
    .banner .text-area {
        margin-bottom: 30px;
    }
    .banner .text-area h1 {
        font-size: 46px;
        line-height: 46px;
    }
    .banner .text-area p {
        font-size: 16px;
    }
    .banner .form-area .box form .form-group {
        text-align: left;
    }
    .services-sec .head-content .btn {
        margin-bottom: 30px;
    }
    .services-sec .head-content {
        margin-bottom: 30px;
    }
    .services-sec {
        text-align: center;
        padding: 50px 0;
    }
    .services-sec .head-content .divider {
        margin: 0 auto 20px;
    }
    .services-sec .slider .box {
        height: auto;
    }
    .feature-sec {
        padding: 60px 0;
    }
    .feature-sec p {
        width: auto;
    }
    .feature-sec ul {
        margin-bottom: 40px;
        justify-content: space-around;
        flex-wrap: wrap;
        padding-bottom: 20px;
    }
    .feature-sec ul li button {
        padding: 10px 10px;
    }
    .feature-sec .img-area img {
        width: 50%;
        padding: 6px 5px;
    }
    .process {
        padding: 60px 0;
        text-align: center;
    }
    .process .side-img {
        transform: none;
        top: 10px;
        left: 0;
    }
    .technologies-sec {
        padding: 40px 0;
        text-align: center;
    }
    .technologies-sec h2 {
        font-size: 20px;
        line-height: 26px;
    }
    .technologies-sec ul li {
        width: calc(50%);
    }
    .slick-track {
        display: flex;
    }
    .testimonials {
        padding: 30px 0 40px;
        background-position: center right;
    }
    .testimonials .slick-active .box {
        margin-left: 0;
        width: 100%;
        box-shadow: none;
        border: 1px solid #ddd;
    }
    .testimonials .box {
        margin-left: 0;
        width: 100%;
        box-shadow: none;
        border: 1px solid #ddd;
    }
    .testimonials .slick-slider {
        padding-top: 0;
        overflow: hidden;
        height: auto;
    }
    .testimonials .right-side {
        padding-left: 0;
        text-align: center;
    }
    .testimonials .right-side h2 {
        font-size: 28px;
    }
    .contact-sec {
        padding: 50px 0;
    }
    .contact-sec .left-side {
        text-align: center;
        margin-bottom: 30px;
    }
    .contact-sec .left-side p {
        width: 100%;
    }
    .contact-sec .left-side .btn {
        padding: 12px 25px;
        margin: 0 5px;
    }
    .contact-sec .submit-btn {
        display: block;
        margin: 30px auto 0;
    }
}