.client-reviews {
    text-align: center;
    padding: 80px 0;
}
.client-reviews .top-content {
    margin-bottom: 60px;
}
.client-reviews h2 {
    font-size: 54px;
    line-height: 55px;
    color: #000;
    text-transform: uppercase;
}
.client-reviews h2 span {
    -webkit-text-stroke: 2px #000;
    color: transparent;
}
.client-reviews .top-content p {
    font-size: 14px;
    width: 550px;
    margin: 0 auto;
}
.client-reviews .box {
    text-align: left;
    border-radius: 20px;
    transition: .4s;
}
.client-reviews .box:hover {
    box-shadow: 4px 4px 100px #FCD6E1;
}
.client-reviews .box .img-area {
    text-align: center;
}
.client-reviews .box .img-area img {
    width: 100px;
    height: 100px;
    border-radius: 50px;
    transform: translateY(50px);
}
.client-reviews .box .box-haed {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    padding: 60px 40px 20px;
    background: url('../../assets/images/testimonials/box-bg.png') center center;
    background-size: cover;
    color: #fff;
}
.client-reviews .box .box-haed h3 {
    margin-bottom: 0px;
    font-family: CD-Regular;
}
.client-reviews .box .box-haed p {
    width: auto;
    margin-bottom: 0;
    font-family: CD-Regular;
}
.client-reviews .box .box-haed .stars {
    display: flex;
    color: #FFE600;
}
.client-reviews .box .box-body {
    padding: 30px 50px;
    text-align: center;
}
.client-reviews .box .box-body p {
    font-size: 13px;
    color: #69717A;
    min-height: 117px;
}


.faq-sec {
    padding: 80px 0;
    background: url('../../assets/images/testimonials/faq-bg.png') center center;
    background-size: cover;
    color: #fff;
}
.faq-sec .top-content {
    margin-bottom: 60px;
}
.faq-sec h3 {
    font-size: 54px;
    line-height: 55px;
    color: #fff;
    text-transform: uppercase;
}
.faq-sec h3 span {
    -webkit-text-stroke: 2px #fff;
    color: transparent;
}
.faq-sec p {
    font-size: 14px;
    width: 400px;
    color: #C6CBD1;
}
.faq-sec .accordion .accordion-item {
    border-radius: 30px;
    margin-bottom: 10px;
}
.faq-sec .accordion .accordion-button {
    background: transparent;
    border: 0;
    box-shadow: none;
    color: #000;
    font-family: CD-Medium;
}


@media (max-width: 991px) {
    .client-reviews h2 {
        font-size: 40px;
        line-height: 42px;
    }
    .client-reviews .top-content p {
        width: 530px;
    }
    .client-reviews .top-content {
        margin-bottom: 45px;
    }
    .client-reviews .box .box-haed h3 {
        font-size: 20px;
    }
    .client-reviews .box .box-haed p {
        font-size: 14px;
    }
    .client-reviews .box .box-body {
        padding: 25px 25px;
    }
    .faq-sec h3 {
        font-size: 36px;
        line-height: 40px;
    }
    .faq-sec p {
        width: 305px;
    }
    .faq-sec .accordion .accordion-button {
        font-size: 13px;
    }
    .faq-sec .accordion .accordion-body {
        font-size: 13px;
    }
}

@media (max-width: 767px) {
    .client-reviews {
        padding: 60px 0;
    }
    .client-reviews h2 {
        font-size: 28px;
        line-height: 30px;
        margin-bottom: 20px;
    }
    .client-reviews .top-content p {
        width: auto;
    }
    .client-reviews .box {
        margin-bottom: 30px;
        box-shadow: 4px 4px 30px #FCD6E1;
    }
    .client-reviews .box:hover {
        box-shadow: 4px 4px 30px #FCD6E1;
    }
    .faq-sec {
        padding: 60px 0;
        text-align: center;
    }
    .faq-sec h3 {
        font-size: 28px;
        line-height: 30px;
    }
    .faq-sec p {
        width: auto;
        margin-bottom: 30px;
    }
    .faq-sec .accordion {
        text-align: left;
    }
    .faq-sec .accordion .accordion-body {
        padding-top: 0;
    }
    .faq-sec img {
        display: none;
    }
}