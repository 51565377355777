.pricing {
    padding: 80px 0 40px;
    overflow: hidden;
}
.pricing .top-content {
    text-align: center;
    margin-bottom: 60px;
}
.pricing .top-content h2 {
    font-size: 50px;
    line-height: 52px;
    color: #000;
    text-transform: uppercase;
}
.pricing .top-content h2 span {
    -webkit-text-stroke: 2px #000;
    color: transparent;
}
.pricing .top-content p {
    font-size: 15px;
    width: 580px;
    margin: 0 auto;
}
.pricing .top-content ul {
    list-style: none;
    padding: 0;
    margin: 0;
    margin-top: 40px;
    display: flex;
    justify-content: space-around;
    border-bottom: 1px solid #000;
}
.pricing .top-content ul li button {
    font-size: 13px;
    color: #000;
    background: transparent;
    border: 0;
    padding: 15px 10px;
}
.pricing .top-content ul li.active button {
    background: linear-gradient(to right, #6dab3c 40%, #0f3fd8 100%);
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent;
}

.pricing .box {
    border: 1px dashed #DF2A3B;
    border-radius: 20px;
    padding: 5px 5px;
    margin-bottom: 30px;
    position: relative;
    transition: .2s;
}
.pricing .box:hover {
    background: linear-gradient(to bottom right, #6dab3c 40%, #0f3fd8 100%);
    color: #fff;
}
.pricing .side-title {
    position: absolute;
    top: 290px;
    right: -150px;
    font-size: 32px;
    transform: rotate(-90deg);
    -webkit-text-stroke: 0.8px #959595;
    font-family: CD-SemiBold;
    color: transparent;
    width: 370px;
    white-space: nowrap;
    text-transform: uppercase;
    text-align: right;
}
.pricing .box:hover .side-title {
    -webkit-text-stroke: 0.8px #fdfdfd;
}
.pricing .box .b-head {
    height: 18vh;
    background: url('../../assets/images/services/price-box-bg.png') center center;
    background-size: cover;
    padding: 40px 40px;
    text-align: center;
    border-radius: 20px;
    font-family: CD-Regular;
    color: #fff;
}
.pricing .box .b-head h3 {
    font-size: 20px;
    font-family: CD-Regular;
    text-transform: capitalize;
    margin-bottom: 5px;
}
.pricing .box .b-head .price {
    font-size: 28px;
    text-transform: uppercase;
}
.pricing .box .b-body {
    padding: 20px 0px;
}
.pricing .box .b-body ul {
    list-style: none;
    margin: 0;
    padding: 0;
    padding: 0 45px 0 20px;
    height: 280px;
    overflow: auto;
}
/* width */
.pricing .box .b-body ::-webkit-scrollbar {
    width: 10px;
}
/* Track */
.pricing .box .b-body ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px grey; 
    border-radius: 10px;
}
/* Handle */
.pricing .box .b-body ::-webkit-scrollbar-thumb {
    background: #ccc; 
    border-radius: 10px;
}
/* Handle on hover */
.pricing .box .b-body ::-webkit-scrollbar-thumb:hover {
    background: #ccc; 
}
.pricing .box .b-body ul li {
    font-size: 14px;
    margin-bottom: 10px;
}
.pricing .box .b-foot {
    display: flex;
    justify-content: center;
    width: 100%;
    padding: 0 10px 20px;
}
.pricing .box .b-foot .action-btn {
    text-align: left;
    display: flex;
    align-items: center;
    background: transparent;
    border: 0;
    font-size: 11px;
    padding: 5px 15px;
    width: 50%;
}
.pricing .box .b-foot .action-btn:first-child {
    border-right: 1px solid #D7DCE1;
}
.pricing .box .b-foot .action-btn:focus,
.pricing .box .b-foot .action-btn:hover {
    color: #000 !important;
}
.pricing .box .b-foot .action-btn img {
    height: 25px;
    margin-right: 8px;
}
.pricing .box .b-foot .action-btn .txt1 {
    display: block;
    color: #000;
    font-weight: 600;
}
.pricing .box .b-foot .action-btn .txt2 {
    display: block;
    color: #858B8F;
}
.pricing .box:hover .b-foot .action-btn,
.pricing .box:hover .b-foot .action-btn .txt1,
.pricing .box:hover .b-foot .action-btn .txt2 {
    color: #fff !important;
}
.pricing .box:hover .b-foot .action-btn img {
    filter: brightness(16.5);
}



@media (max-width: 1024px) {
    .pricing .top-content h2 {
        font-size: 40px;
        line-height: 42px;
    }
    .pricing .top-content p {
        width: 450px;
    }
    .pricing .box .b-head h3 {
        font-size: 16px;
    }
    .pricing .box .b-head .price {
        font-size: 24px;
    }
    .pricing .box .b-foot .action-btn {
        padding: 5px 6px;
    }
}


@media (max-width: 991px) {
    .pricing {
        padding: 60px 0;
    }
    .pricing .top-content h2 {
        font-size: 28px;
        line-height: 32px;
    }
    .pricing .top-content p {
        width: 400px;
    }
    .pricing .box .b-head {
        padding: 30px 25px;
    }
    .pricing .box .b-head h3 {
        font-size: 14px;
    }
    .pricing .box .b-head .price {
        font-size: 16px;
    }
    .pricing .box .b-body ul li {
        font-size: 11px;
        margin-bottom: 10px;
    }
    .pricing .box .b-foot {
        display: block;
    }
    .pricing .box .b-foot .action-btn {
        margin-bottom: 10px;
    }
}


@media (max-width: 767px) {
    .pricing {
        padding: 60px 0 30px;
    }
    .pricing .top-content h2 {
        font-size: 22px;
        line-height: 30px;
    }
    .pricing .top-content p {
        width: auto;
    }
    .pricing .box {
        margin-bottom: 20px;
    }
    .pricing .side-title {
        top: 190px;
    }
    .pricing .box .b-foot {
        display: flex;
    }
    .pricing .box .b-foot .action-btn {
        padding: 5px 15px;
    }
}