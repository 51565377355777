@font-face {
    font-family: CD-Regular;
    src:url(../fonts/ClashDisplay-Regular.otf);
    font-weight: 400;
    font-style: normal;
    font-display: swap;
}
  
@font-face {
    font-family:CD-Medium;
    src:url(../fonts/ClashDisplay-Medium.otf);
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}
  
@font-face {
    font-family: CD-SemiBold;
    src:url(../fonts/ClashDisplay-Semibold.otf);
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}
  
 @font-face {
    font-family: CD-Bold;
    src:url(../fonts/ClashDisplay-Bold.otf);
    font-weight: 700;
    font-style: normal;
    font-display: swap;
}

.side-btn {
    display: none;
    transition: .2s;
}
.side-btn.active {
    display: inline-block;
    background: linear-gradient(to bottom right, #6dab3c 40%, #0f3fd8 100%);;
    color: #fff ;
    height: 50px;
    line-height: 50px;
    position: fixed;
    top: 115px;
    right: 0px;
    text-align: center;
    text-transform: uppercase;
    -moz-transform:rotate(-90deg);
    -ms-transform:rotate(-90deg);
    -o-transform:rotate(-90deg);
    -webkit-transform:rotate(-90deg);
    transform-origin: bottom right;
    width: 150px;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    z-index: 11;
}

body {
    overflow-x: hidden;
    position: relative;
    font-family: CD-Regular;
}

a {
    transition: all .2s ease-in-out;
    text-decoration: none;
    letter-spacing: 1px;
}
a:hover {
    text-decoration: none;
    color: #0093DF
}
.btn {
    color: #fff;
    outline: none;
    border: 0;
}
.btn:active,
.btn:hover,
.btn.focus,
.btn:focus {
    outline: 0;
    box-shadow: none;
    color: #fff !important;
}
button:focus,
button:hover {
    outline: 0;
    color: #fff;
}

input:focus {
    outline: none;
}
.form-control:focus {
    border-color: #ced4da;
    outline: 0;
    box-shadow: none;
}
h1,
h2 {
    word-spacing: 8px;
}
h1,
h2,
h3 {
    font-weight: 700;
    font-family: CD-Bold;
    letter-spacing: 1px;
}

img {
    max-width: 100%;
}
p {
    letter-spacing: 1px;
}