.outer-content {
    padding: 150px 0 80px;
    background: #eee;
}
.outer-content h1 {
    color: #000;
    font-size: 28px;
    line-height: 0;
    text-transform: uppercase;
    font-weight: 700;
    margin-bottom: 30px;
}

.outer-content h2 {
    font-size: 30px;
    color: #000;
    font-weight: 700;
    margin-bottom: 20px;
    font-family: CD-SemiBold;
}
.outer-content h3 {
    color: #000;
    font-size: 20px;
    margin-top: 20px;
    margin-bottom: 10px;
    font-family:CD-Medium;
}
.outer-content h4 {
    color: #000;
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 20px;
}
.outer-content b {
    font-size: 14px;
}
.outer-content ol li::marker {
    font-family: Arial, Helvetica, sans-serif;
}


@media (max-width: 767px) {
    .outer-content {
        padding: 140px 0;
    }
}