.services {
    padding: 80px 0;
    user-select: none;
    overflow: hidden;
}
.services .slick-track {
    display: flex;
}
.services .top-content {
    text-align: center;
    margin-bottom: 120px;
}
.services .top-content h2 {
    font-size: 50px;
    line-height: 52px;
    color: #000;
    text-transform: uppercase;
}
.services .top-content h2 span {
    -webkit-text-stroke: 2px #000;
    color: transparent;
}
.services .top-content p {
    font-size: 16px;
    width: 500px;
    margin: 0 auto;
}

.services .row-2 {
    margin-top: 130px;
}

.services .text-area h3 {
    font-size: 30px;
    line-height: 32px;
    color: #151515;
    text-transform: uppercase;
}
.services .text-area h3 span {
    background: linear-gradient(to right, #6dab3c 40%, #0f3fd8 100%);
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent;
}
.services .text-area .btn-area {
    margin-top: 50px;
}
.services .text-area .btn {
    font-size: 14px;
    padding: 15px 35px;
    background: linear-gradient(to bottom right, #6dab3c 40%, #0f3fd8 100%);
    border: 2px solid transparent;
    border-radius: 35px;
    margin-right: 15px;
}
.services .text-area .btn.danger {
    background: linear-gradient(to bottom right, #3b72ef 40%, #39d5aa 100%);
    border: 2px solid transparent;
}

/* .services .img-area img {
    transform: scale(1.3);
} */

.services .slick-dots {
    text-align: center;
    margin-top: 40px;
}
.services .slick-dots li button {
    border-radius: 10px;
    background-color: #cbcbcb;
    border: none;
    height: 15px;
    width: 15px;
    font-size: 0;
}
.services .slick-dots li, .slick-dots li {
    border: none;
    display: inline-block;
    margin: 0 20px 0 0;
    overflow: hidden;
    padding: 0;
    vertical-align: middle;
    width: auto;
}
.services .slick-dots li button:before {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: transparent;
    content: "•";
    font-family: slick;
    font-size: 6px;
    height: 20px;
    left: 0;
    line-height: 20px;
    opacity: 1;
    position: absolute;
    text-align: center;
    top: 0;
    width: 20px;
}

.services .slick-dots li.slick-active button {
    background: linear-gradient(to bottom right, #6dab3c 40%, #0f3fd8 100%);
    border: none;
    height: 15px;
    width: 15px;
}
.services .slick-dots li.slick-active button:before {
    color: transparent;
    opacity: .75;
}





.price-sec {
    padding: 80px 0;
}
.price-sec .slick-track {
    display: flex;
}
.price-sec .top-content {
    text-align: center;
    margin-bottom: 60px;
}
.price-sec .top-content h2 {
    font-size: 50px;
    line-height: 52px;
    color: #000;
    text-transform: uppercase;
}
.price-sec .top-content h2 span {
    -webkit-text-stroke: 2px #000;
    color: transparent;
}
.price-sec .top-content p {
    font-size: 16px;
    width: 550px;
    margin: 0 auto;
}
.price-sec .box {
    border: 1px dashed #DF2A3B;
    border-radius: 20px;
    padding: 5px 5px;
    transition: .2s;
    position: relative;
}
.price-sec .box:hover {
    background: #DF2A3B;
    color: #fff;
}
.price-sec .side-title {
    position: absolute;
    top: 250px;
    right: -110px;
    font-size: 32px;
    transform: rotate(-90deg);
    -webkit-text-stroke: 0.8px #959595;
    font-family: CD-SemiBold;
    color: transparent;
}
.price-sec .box .b-head {
    background: url('../../assets/images/services/price-box-bg.png') center center;
    background-size: cover;
    padding: 40px 40px;
    text-align: center;
    border-radius: 20px;
    font-family: CD-Regular;
    color: #fff;
}
.price-sec .box .b-head h3 {
    font-size: 20px;
    font-family: CD-Regular;
    text-transform: capitalize;
    margin-bottom: 5px;
}
.price-sec .box .b-head .price {
    font-size: 28px;
    text-transform: uppercase;
}
.price-sec .box .b-body {
    padding: 20px 20px;
}
.price-sec .box .b-body ul {
    list-style: none;
    margin: 0;
    padding: 0;
}
.price-sec .box .b-body ul li {
    font-size: 14px;
    margin-bottom: 10px;
}
.price-sec .box .b-foot {
    display: flex;
    justify-content: center;
    width: 100%;
    padding: 0 10px 20px;
}
.price-sec .box .b-foot .action-btn {
    text-align: left;
    display: flex;
    align-items: center;
    background: transparent;
    border: 0;
    font-size: 11px;
    padding: 5px 15px;
}
.price-sec .box .b-foot .action-btn:first-child {
    border-right: 1px solid #D7DCE1;
}
.price-sec .box .b-foot .action-btn:focus,
.price-sec .box .b-foot .action-btn:hover {
    color: #000 !important;
}
.price-sec .box .b-foot .action-btn img {
    width: 20px;
    margin-right: 8px;
}
.price-sec .box .b-foot .action-btn .txt1 {
    display: block;
}
.price-sec .box .b-foot .action-btn .txt2 {
    display: block;
    color: #858B8F;
}
.price-sec .box:hover .b-foot .action-btn,
.price-sec .box:hover .b-foot .action-btn .txt2 {
    color: #fff !important;
}
.price-sec .box:hover .b-foot .action-btn img {
    filter: brightness(16.5);
}



@media (max-width: 1024px) {
    .services .top-content {
        margin-bottom: 100px;
    }
    .services .top-content h2 {
        font-size: 40px;
        line-height: 42px;
    }
    .services .top-content p {
        width: 450px;
    }
    .services .text-area h3 {
        font-size: 26px;
        line-height: 30px;
    }
    .services .text-area p {
        font-size: 14px;
    }
    .services .text-area .btn {
        font-size: 13px;
        padding: 13px 30px;
    }
    .services .row-2 {
        margin-top: 110px;
    }
    .price-sec .top-content h2 {
        font-size: 40px;
        line-height: 42px;
    }
    .price-sec .top-content p {
        width: 450px;
    }
    .price-sec .box .b-head h3 {
        font-size: 16px;
    }
    .price-sec .box .b-head .price {
        font-size: 24px;
    }
    .price-sec .box .b-foot .action-btn {
        padding: 5px 6px;
    }
}


@media (max-width: 991px) {
    .services .top-content h2 {
        font-size: 28px;
        line-height: 32px;
    }
    .services .top-content p {
        width: 400px;
    }
    .services .text-area .btn {
        font-size: 12px;
        padding: 12px 20px;
    }
    .services .text-area h3 {
        font-size: 22px;
        line-height: 26px;
    }
    .price-sec {
        padding: 60px 0 30px;
    }
    .price-sec .top-content h2 {
        font-size: 28px;
        line-height: 32px;
    }
    .price-sec .top-content p {
        width: 400px;
    }
    .price-sec .box .b-head {
        padding: 30px 25px;
    }
    .price-sec .box .b-head h3 {
        font-size: 14px;
    }
    .price-sec .box .b-head .price {
        font-size: 16px;
    }
    .price-sec .box .b-body ul li {
        font-size: 11px;
        margin-bottom: 10px;
    }
    .price-sec .box .b-foot {
        display: block;
    }
    .price-sec .box .b-foot .action-btn {
        margin-bottom: 10px;
    }
}


@media (max-width: 767px) {
    .services {
        padding: 50px 0;
    }
    .services .top-content {
        margin-bottom: 40px;
    }
    .services .top-content h2 {
        font-size: 26px;
        line-height: 30px;
    }
    .services .top-content h2 span {
        -webkit-text-stroke: 1px #000;
    }
    .services .top-content p {
        width: auto;
    }
    .services .text-area {
        text-align: center;
        margin-bottom: 30px;
    }
    .services .text-area p {
        font-size: 13px;
    }
    .services .text-area .btn-area {
        margin-top: 30px;
    }
    .services .img-area img {
        transform: none;
    }
    .services .row-2 {
        margin-top: 60px;
        flex-direction: column-reverse;
    }
    .price-sec {
        padding: 60px 0 30px;
    }
    .price-sec .top-content h2 {
        font-size: 22px;
        line-height: 30px;
    }
    .price-sec .top-content p {
        width: auto;
    }
    .price-sec .box {
        margin-bottom: 20px;
    }
    .price-sec .box .b-foot {
        display: flex;
    }
    .price-sec .box .b-foot .action-btn {
        padding: 5px 15px;
    }
    
    
}