/* header */
.header {
    position: fixed;
    width: 100%;
    padding: 30px 0;
    z-index: 111;
}
.header:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    width: 100%;
    height: 100%;
    background: linear-gradient(180deg, #000000 -6.76%, rgba(0, 0, 0, 0) 100%);
}
.header .toggle-btn {
    display: none;
}
.header .nav {
    position: relative;
    z-index: 1;
    display: flex;
    align-items: center;
    width: 100%;
}
.header .nav .logo {
    font-size: 50px;
    /* color: #10B775; */
    background: linear-gradient(to right, #6dab3c 40%, #0f3fd8 100%);
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent;
    font-family: CD-SemiBold;
    line-height: 36px;
    text-transform: uppercase;
    text-align: center;
}
.header .nav .logo span {
    color: #fff;
    font-size: 12.3px;
    display: block;
    text-align: end;
}
.header .nav ul {
    display: flex;
    align-items: center;
    list-style: none;
    padding: 0;
    margin: 0;
}
.header .nav ul li {
    margin-left: 18px;
}
.header .nav ul li a {
    font-size: 12px;
    color: #fff;
    padding: 10px 0;
    position: relative;
}
.header .nav ul li a.active:after,
.header .nav ul li:hover a:after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 15px;
    height: 2px;
    display: block;
    background: linear-gradient(to bottom right, #3b72ef 40%, #39d5aa 100%);
}
.header .nav ul li.cta-li:hover a:after {
    content: '';
    display: none;
}

.header .nav .btn {
    font-size: 13px;
    padding: 10px 28px;
    background: linear-gradient(to bottom right, #3b72ef 40%, #39d5aa 100%);
    border: 2px solid transparent;
    border-radius: 35px;
    text-align: left;
}
.header .nav .btn.danger {
    background: linear-gradient(to bottom right, #6dab3c 40%, #0f3fd8 100%);
    border: 2px solid transparent;
}



@media (max-width: 1024px) {
    .header .toggle-btn {
        background: transparent;
        border: 0;
        color: #fff;
        font-size: 18px;
        position: absolute;
        z-index: 1111;
        display: block;
        right: -18px;
    }
    .header .nav ul {
        flex-direction: column;
        position: absolute;
        right: -600px;
        top: -30px;
        width: 350px;
        height: 100vh;
        padding-top: 70px;
        background: #000000;
        align-items: flex-start;
        transition: .4s;
    }
    .footer-links h3:after {
        width: 100%;
    }
    .header .nav ul.show {
        right: -140px;
    }
    .header .nav ul li {
        margin-left: 20px;
        width: 100%;
    }
    .header .nav ul .cta-li {
        /* display: none; */
    }
    .header .nav ul li a {
        font-size: 14px;
        padding: 15px 0;
        width: 100%;
        display: block;
    }
    .header .nav .btn {
        display: inline;
        margin-bottom: 20px;
        min-width: 160px;
        text-align: center;
    }
}

@media (max-width: 767px) {
    .header .nav ul.show {
        right: -45px;
        width: 300px;
    }
    .header .toggle-btn {
        right: 5px;
    }
}